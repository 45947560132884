import React, { useState, useEffect, useRef, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NumericFormat } from 'react-number-format';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import tinycolor from 'tinycolor2';
import chroma from 'chroma-js';
import logo from './logo.png';
import { jwtDecode } from "jwt-decode";

function App() {
  const [isOpen, setIsOpen] = useState(false); // Dropdown menu open/close state
  const [activePage, setActivePage] = useState('tasks'); // Currently active page
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomLevel2, setZoomLevel2] = useState(1);
  const [zoomLevel3, setZoomLevel3] = useState(1);
  const [widthLevel1, setWidthLevel1] = useState("40%");
  const [widthLevel2, setWidthLevel2] = useState("70%");
  const [widthLevel3, setWidthLevel3] = useState("35px");
  const [widthLevel4, setWidthLevel4] = useState("16px");
  const [widthLevel5, setWidthLevel5] = useState("300px");
  const [topLeftTableTop, setTopLeftTableTop] = useState("110px");
  const [topCenteredTableTop, setCenteredTableTop] = useState("450px");
  const [topCenteredTable2Top, setCenteredTable2Top] = useState("100px");
  const [topTableContainerTop, setTableContainerTop] = useState("200px");
  const [fontSize, setFontSize] = useState('18px');

    const [colors, setColors] = useState({
        evenRowColor1: '',
        oddRowColor1: '',
        evenTextColor1: '',
        oddTextColor1: '',
        evenRowColor2: '',
        oddRowColor2: '',
        evenTextColor2: '',
        oddTextColor2: '',
        evenRowColor3: '',
        oddRowColor3: '',
        evenTextColor3: '',
        oddTextColor3: ''
    });

    useEffect(() => {
        const evenRowColor1 = getAdjustedColor(oddColor(1, 45), 10);
        const oddRowColor1 = getAdjustedColor(evenRowColor1, 20);
        const evenTextColor1 = getTextColor(evenRowColor1);
        const oddTextColor1 = getTextColor(oddRowColor1);

        const evenRowColor2 = getAdjustedColor(oddColor(1, 45), 10);
        const oddRowColor2 = getAdjustedColor(evenRowColor2, 20);
        const evenTextColor2 = getTextColor(evenRowColor2);
        const oddTextColor2 = getTextColor(oddRowColor2);

        const evenRowColor3 = getAdjustedColor(oddColor(1, 45), 10);
        const oddRowColor3 = getAdjustedColor(evenRowColor3, 20);
        const evenTextColor3 = getTextColor(evenRowColor3);
        const oddTextColor3 = getTextColor(oddRowColor3);

        setColors({
            evenRowColor1,
            oddRowColor1,
            evenTextColor1,
            oddTextColor1,
            evenRowColor2,
            oddRowColor2,
            evenTextColor2,
            oddTextColor2,
            evenRowColor3,
            oddRowColor3,
            evenTextColor3,
            oddTextColor3
        });
    }, []);

  const updateZoomLevel = () => {
    const width = window.innerWidth;

    if (width <= 768) {
      // Mobile: screen width <= 768px
      setZoomLevel(0.25); // Example: 80% zoom
      setZoomLevel2(0.65);
      setZoomLevel3(0.70);
      setWidthLevel1("70%");
      setWidthLevel2("90%");
      setWidthLevel3("60px");
      setWidthLevel4("11px");
      setWidthLevel5("245px");
      setTopLeftTableTop("365px");
      setCenteredTableTop("825px");
      setCenteredTable2Top("365px");
      setTableContainerTop("465px");
      setFontSize('24px');
    } else if (width <= 1024) {
      // Tablet: screen width > 768px and <= 1024px
      setZoomLevel(0.50); // Example: 100% zoom
      setZoomLevel2(0.75);
      setZoomLevel3(0.80);
      setWidthLevel1("55%");
      setWidthLevel2("85%");
      setWidthLevel3("50px");
      setWidthLevel4("12px");
      setWidthLevel5("260px");
      setTopLeftTableTop("200px");
      setCenteredTableTop("650px");
      setCenteredTable2Top("200px");
      setTableContainerTop("300px");
      setFontSize('21px');
    } else {
      // Desktop: screen width > 1024px
      setZoomLevel(1); // Example: 120% zoom
      setZoomLevel2(1);
      setZoomLevel3(1);
      setWidthLevel1("30%");
      setWidthLevel2("60%");
      setWidthLevel3("35px");
      setWidthLevel4("16px");
      setWidthLevel5("300px");
      setTopLeftTableTop("100px");
      setCenteredTableTop("450px");
      setCenteredTable2Top("100px");
      setTableContainerTop("200px");
      setFontSize('18px');
    }
  };

  const getAdjustedColor = (color, amount) => {
    return tinycolor(color).lighten(amount).toString();
  };

  const getTextColor = (bgColor) => {
    const lightenedColor = getAdjustedColor(bgColor, 10);
    const luminance = chroma(lightenedColor).luminance();
    return luminance > 0.5 ? 'black' : 'white';
  };

const getRandomLightColor = () => {
  // Define the range for light colors by limiting the RGB values
  const getRandomChannelValue = () => Math.floor(Math.random() * 128) + 128; // Generates a value between 128 and 255

  const r = getRandomChannelValue();
  const g = getRandomChannelValue();
  const b = getRandomChannelValue();

  return `rgb(${r}, ${g}, ${b})`;
};


const pageStyle2 = {
  minHeight: '100%', // Ensure page takes full height
  zoom: zoomLevel2,
  width: '100%',
  zoom: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Center content vertically
  alignItems: 'center', // Center content horizontally
  backgroundColor: 'white',
  padding: '20px',
  boxSizing: 'border-box',
};

const tableContainerStyle = {
  fontSize: widthLevel4,
  padding: '0px', // Remove extra padding
  margin: '0px',  // Remove extra margin
  width: '100%',  // Ensure containers are full width to align tables correctly
  display: 'flex',
  justifyContent: 'center', // Center tables horizontally
};

const tableStyle2 = {
  borderSpacing: '0',
  padding: '0',
  width: '45%', // Ensure all tables have the same width if needed
};

const styles = {
  topLeftTable: {
    position: 'absolute',
    top: topLeftTableTop,
    left: '20px',
    width: '550px', // Adjust width as needed
    backgroundColor: '#ccffcc',
  },
  centeredTable: {
    position: 'absolute',
    fontSize: fontSize,
    top: topCenteredTableTop,
    width: '25%', // Let the table width adjust automatically
    margin: '0px 0px', // Center horizontally
    backgroundColor: '#ffcccc',
  },
  centeredTable2: {
    position: 'absolute',
    fontSize: fontSize,
    top: topCenteredTable2Top,
    width: '25%', // Let the table width adjust automatically
    margin: '0px 0px', // Center horizontally
    backgroundColor: '#ffcccc',
  },
  leftContainer: {
    width: '100%', // Ensure the container takes up full width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    marginLeft: '0px', // No left margin needed
    height: '50vh', // Ensure the container takes up full height
  },
  tableContainer: {
    position: 'absolute',
    fontSize: fontSize,
    top: topTableContainerTop,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '20px auto',
  },
  bgMario: {
    backgroundColor: '#ffcccc',
    flex: 1,
    margin: '0 0px',
  },
  bgMarioThead: {
    backgroundColor: '#ff9999',
  },
  bgJosipa: {
    backgroundColor: '#ccffcc',
    flex: 1,
    margin: '0 0px',
  },
  bgJosipaThead: {
    backgroundColor: '#99ff99',
  },
  bgWhite: {
    backgroundColor: '#ffffff',
    flex: 1,
    margin: '0 0px',
  },
  bgWhiteThead: {
    backgroundColor: '#ffffff',
  },
  bgIvana: {
    backgroundColor: '#ccccff',
    flex: 1,
    margin: '0 0px',
  },
  bgIvanaThead: {
    backgroundColor: '#9999ff',
  },
  bgMarta: {
    backgroundColor: '#ffffcc',
    flex: 1,
    margin: '0 0px',
  },
  bgMartaThead: {
    backgroundColor: '#ffff99',
  },
};

  useEffect(() => {
    // Set zoom level on component mount
    updateZoomLevel();

    // Update zoom level on window resize
    window.addEventListener('resize', updateZoomLevel);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateZoomLevel);
    };
  }, []);


const pageContainer = {
  zoom: zoomLevel3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const tableContainer3 = {
    zoom: zoomLevel2,
    width: widthLevel1,
};

const tableContainer2 = {
    zoom: zoomLevel2,
    width: widthLevel2,
};


const pageContent = {
  zoom: zoomLevel3,
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '5px',
};



const pageStyle = {
  minHeight: '100%', // Ensure page takes full height
  width: '100%',
  zoom: zoomLevel,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // Align content to the top
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '20px',
  boxSizing: 'border-box',
};



  const [selectedTaskByTask, setSelectedTaskByTask] = useState('');
  const [selectedTaskByUser, setSelectedTaskByUser] = useState('');
  const [selectedTaskByExpense, setSelectedTaskByExpense] = useState('');
  const [tables, setTables] = useState([]);

  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    axios.get("/api/tables")
      .then(response => {
        setTables(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the tables!", error);
      });
  }, []);

const tableColors = {};

const generateTableColors = (id) => {
  if (!tableColors[id]) {
    const evenRowColor = getRandomLightColor();
    const oddRowColor = getAdjustedColor(evenRowColor, -8);
    const evenTextColor = getTextColor(evenRowColor);
    const oddTextColor = getTextColor(oddRowColor);
    const headerColor = getAdjustedColor(evenRowColor, -16);
    const headerTextColor = getTextColor(headerColor);

    tableColors[id] = {
      evenRowColor,
      oddRowColor,
      evenTextColor,
      oddTextColor,
      headerColor,
      headerTextColor
    };
  }

  return tableColors[id];
};

const stringToLightColor = (str) => {
    // Ensure the input string is a valid 14-digit string (yyyyMMddHHmmss)
    if (!/^\d{14}$/.test(str)) {
        throw new Error('Invalid date-time string format');
    }
    
    // Convert the string to a numeric value
    const numericValue = parseInt(str, 10);
    
    // Use modulus operation to ensure values are within the RGB range (0-255)
    const red = (numericValue % 256);
    const green = ((numericValue >> 8) % 256);
    const blue = ((numericValue >> 16) % 256);

    // Return the color in RGB format
    return `rgb(${red}, ${green}, ${blue})`;
};

const numberToLightColor = (num) => {
  // Normalize the number to a range between 0 and 360
  const hue = (num * 60) % 360;
  return `hsl(${hue}, 100%, 80%)`; // Light color with 80% lightness
};

const integerToLightColor = (id) => {
  // Convert integer to string
  const str = id.toString();
  // Use the string in the color generation function
  return stringToLightColor(str);
};

const evenColor = (id, lightness) => `hsl(${(id * 37 + 180) % 360}, 30%, ${lightness}%)`;  // Prigušena boja za even redove
const oddColor = (id, lightness) => `hsl(${(id * 53 + 220) % 360}, 30%, ${lightness}%)`;   // Prigušena boja za odd redove
const headerColor = (id, lightness) => `hsl(${(id * 71 + 240) % 360}, 30%, ${lightness}%)`; // Prigušena boja za header

const renderTable = (table) => {
    const headerColor2 = getAdjustedColor(oddColor(table.id+1, 45), 10);
    const oddRowColor = getAdjustedColor(headerColor2,10);
    const evenRowColor = getAdjustedColor(headerColor2,20);
    const evenTextColor = getTextColor(evenRowColor);
    const oddTextColor = getTextColor(oddRowColor);
    const headerTextColor = getTextColor(headerColor2);

  return (
    <table key={table.id} style={{ ...tableContainer2, marginTop: 0, marginBottom: 0 }} className="table table-bordered rounded-corners table-success">
      <thead>
        {table.titles.map((title, i) => (
          <tr key={i}>
            <th colSpan={table.headers.length} style={{ textAlign: 'center', fontSize: '21px', backgroundColor: headerColor2, color: headerTextColor, padding: 10 }}>
              {title}
            </th>
          </tr>
        ))}
        <tr>
          {table.headers.map((header, headerIndex) => (
            <th key={headerIndex} style={{ textAlign : headerIndex === 0 ? 'center' : headerIndex === 1 ? 'left' : headerIndex === 2 ? 'center' : 'right', width: headerIndex === 0 ? '10%' : headerIndex === 1 ? '50%' : headerIndex === 2 ? '10%' : '30%', backgroundColor: headerColor2, color: headerTextColor, padding: 10 }}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.rows.map((row, rowIndex) => (
          <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? evenRowColor : oddRowColor }}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} style={{ textAlign: cellIndex === 0 ? 'center' : cellIndex === 1 ? 'left' : cellIndex === 2 ? 'center' : 'right', backgroundColor: rowIndex % 2 === 0 ? evenRowColor : oddRowColor, color: rowIndex % 2 === 0 ? evenTextColor : oddTextColor, padding: 10 }}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
        {table.total && (
          <tr>
            <td colSpan={table.headers.length - 1} style={{ textAlign: 'right', backgroundColor: headerColor2, color: headerTextColor, padding: 10 }}>
              Ukupno
            </td>
            <td style={{ textAlign: 'right', backgroundColor: headerColor2, color: headerTextColor, padding: 10 }}>
              {table.total[table.total.length - 1]}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};


  // Handle page change and close dropdown
  const handlePageChange = (page) => {
    setActivePage(page);
    setIsOpen(false); // Close dropdown when an option is clicked
  };

  // Handle clicks outside of the dropdown to close it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach event listener for clicks outside of the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [tasksByTask, setTasksByTasks] = useState([]);
  const [tasksByUser, setTasksByUsers] = useState([]);
  const [tasksByExpense, setTasksByExpenses] = useState([]);
  const [section, setSection] = useState('login');
  const [currentPage, setCurrentPage] = useState(1);
  const [intervalPage, setIntervalPage] = useState(1);
  const [timerInterval, setTimerInterval] = useState(2000);
  const [taskByTask, setTaskByTask] = useState({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
  });

  const [newTaskByTask, setNewTaskByTask] = useState({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
  });

  const [taskByUser, setTaskByUser] = useState({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
  });

  const [taskByExpense, setTaskByExpense] = useState({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: '',
    balance: 0
  });

  useEffect(() => {
    if (activePage === 'expensesdemo') {
      const interval = setInterval(() => {
        if (intervalPage < 44) {
          if (intervalPage !== 1+15 || intervalPage !== 1+16 || intervalPage !== 1+17 || intervalPage !== 1+19 || intervalPage !== 1+20 || intervalPage !== 1+21 || intervalPage !== 1+23 || intervalPage !== 1+24 || intervalPage !== 1+25 || intervalPage !== 1+27 || intervalPage !== 1+28 || intervalPage !== 1+29 || intervalPage !== 1+31 || intervalPage !== 1+32 || intervalPage !== 1+33 || intervalPage !== 1+35 || intervalPage !== 1+36 || intervalPage !== 1+37 || intervalPage !== 1+39 || intervalPage !== 1+40 || intervalPage !== 1+41) {
          setCurrentPage(intervalPage);
        }
          if (intervalPage === 1+15 || intervalPage === 1+16 || intervalPage === 1+17 || intervalPage === 1+19 || intervalPage === 1+20 || intervalPage === 1+21 || intervalPage === 1+23 || intervalPage === 1+24 || intervalPage === 1+25 || intervalPage === 1+27 || intervalPage === 1+28 || intervalPage === 1+29 || intervalPage === 1+31 || intervalPage === 1+32 || intervalPage === 1+33 || intervalPage === 1+35 || intervalPage === 1+36 || intervalPage === 1+37 || intervalPage === 1+39 || intervalPage === 1+40 || intervalPage === 1+41) {
          setCurrentPage(intervalPage);
        }
        }
        if (intervalPage === 48) {
          setCurrentPage(1);        
          setIntervalPage(1); // Reset intervalPage to 1
        } else {
          if (intervalPage !== 1+15 || intervalPage !== 1+16 || intervalPage !== 1+17 || intervalPage !== 1+19 || intervalPage !== 1+20 || intervalPage !== 1+21 || intervalPage !== 1+23 || intervalPage !== 1+24 || intervalPage !== 1+25 || intervalPage !== 1+27 || intervalPage !== 1+28 || intervalPage !== 1+29 || intervalPage !== 1+31 || intervalPage !== 1+32 || intervalPage !== 1+33 || intervalPage !== 1+35 || intervalPage !== 1+36 || intervalPage !== 1+37 || intervalPage !== 1+39 || intervalPage !== 1+40 || intervalPage !== 1+41) {
          setTimerInterval(2000);
          setIntervalPage(intervalPage + 1); // Increment intervalPage
        }
          if (intervalPage === 1+15 || intervalPage === 1+16 || intervalPage === 1+17 || intervalPage === 1+19 || intervalPage === 1+20 || intervalPage === 1+21 || intervalPage === 1+23 || intervalPage === 1+24 || intervalPage === 1+25 || intervalPage === 1+27 || intervalPage === 1+28 || intervalPage === 1+29 || intervalPage === 1+31 || intervalPage === 1+32 || intervalPage === 1+33 || intervalPage === 1+35 || intervalPage === 1+36 || intervalPage === 1+37 || intervalPage === 1+39 || intervalPage === 1+40 || intervalPage === 1+41) {
          setTimerInterval(500);
          setIntervalPage(intervalPage + 1); // Increment intervalPage
        }

        }
      }, timerInterval); // 0.5 seconds for each page

      return () => clearInterval(interval); // Clear interval when component unmounts or activePage changes
    } else {
       setCurrentPage(1);        
       setIntervalPage(1); // Reset intervalPage to 1
    }
  }, [activePage, intervalPage]); // Add intervalPage to the dependency array

const handleTaskChange = (e) => {
  const selectedTask = e.target.value;
  const selectedIndex = e.target.selectedIndex;

  if (selectedTask === "") {
    setSelectedTaskByTask(selectedTask); // Update the state
    resetTaskByTaskForm();
    //console.log("The first option (index 0) is selected");
    // You can handle this case as needed
  } else { 
  setSelectedTaskByTask(selectedTask); // Update the state
  const taskId = findTaskIdByName(selectedTask); // Use the value directly from the event

  if (taskId) {
     updateAndSelectTask(taskId);
       //setTaskByTask(findTaskByTaskByName(selectedTask));
const foundTask = findTaskByTaskByName(selectedTask);

// Format the limitValue before setting it in the state
const formattedLimitValue = new Intl.NumberFormat('en-US', { 
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2 
}).format(Number(foundTask.limitValue));

// Create a new object with the formatted limitValue
const updatedTask = {
  ...foundTask,
  limitValue: formattedLimitValue
};

// Set the state with the updated task
setTaskByTask(updatedTask);
  } else {
  }
}
};

  const handleUserChange = (e) => {
  const selectedUser = e.target.value;
  const selectedIndex = e.target.selectedIndex;

  if (selectedUser === "") {
    setSelectedTaskByUser(selectedUser); // Update the state
    resetTaskByUserForm();
  } else {
    setSelectedTaskByUser(selectedUser); // Update the state

  const taskId = findTaskIdByNameAndEmail(selectedTaskByTask, selectedUser); // Use the value directly from the event

  console.log(taskId);

  if (taskId) {
     setTaskByUser(findTaskByUserByNameAndEmail(selectedTaskByTask, selectedUser));
  } else {
  }
  }
  };

  const findTaskIdByName = (taskName) => {
    const task = tasksByTask.find(task => task.taskName === taskName);
    return task ? task.id : null;
  };

  const findTaskByTaskByName = (taskName) => {
    const task = tasksByTask.find(task => task.taskName === taskName);
    return task ? task : null;
  };

  const findTaskByUserByNameAndEmail = (taskName, userEmail) => {
    const task = tasksByUser.find(
      (task) => task.taskName === taskName && task.userEmail === userEmail
    );
    return task ? task : null;
  };


  const findTaskIdByCurrent = (current) => {
    const task = tasksByTask.find(task => task.current === current);
    return task ? task.taskName : "";
  };

  const findTaskIdByNameAndEmail = (taskName, userEmail) => {
    const task = tasksByUser.find(
      (task) => task.taskName === taskName && task.userEmail === userEmail
    );
    return task ? task.id : null;
  };

  const [tasks, setTasks] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showTaskParticipant, setShowTaskParticipant] = useState(false); // To toggle tasks and participants
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [showParticipantForm, setShowParticipantForm] = useState(false);
  const [newTask, setNewTask] = useState({ name: '', unit: '', quantity: '', costDescription: '', cost: '' });
  const [selectedTask, setSelectedTask] = useState('');
  const [newParticipant, setNewParticipant] = useState({ name: '', email: '' });
  const [selectedParticipant, setSelectedParticipant] = useState('');

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setShowTaskForm(false);
    setShowParticipantForm(false);
  };

  const handleParticipantInputChange = (e) => {
    const { name, value } = e.target;
    setNewParticipant({ ...newParticipant, [name]: value });
  };

  const handleDeleteTask = () => {
    const taskId = findTaskIdByName(selectedTaskByTask);
    if (taskId) {
      deleteTaskByTask(taskId);
      setSelectedTaskByTask(findTaskIdByCurrent(true));
    } else {
      alert('Task not found or not selected.');
    }
  };

  const handleDeleteUser = () => {
    const taskId = findTaskIdByNameAndEmail(selectedTaskByTask, selectedTaskByUser);
    if (taskId) {
      deleteTaskByUser(taskId);
      setSelectedTaskByTask(findTaskIdByCurrent(true));
    } else {
      alert('Task not found or not selected.');
    }
  };


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [taskNameA, setTaskNameA] = useState('');
  const [message, setMessage] = useState('');
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [editingGroup, setEditingGroup] = useState(false);
  const [editingTask, setEditingTask] = useState(false);
  const [editingTask2, setEditingTask2] = useState(false);
  const [editingExpense, setEditingExpense] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (token && storedUsername && emailRegex.test(storedUsername)) {
      try {
        // Decode token to check its expiration
        const decodedToken = jwtDecode(token);

        // Check if the token is expired
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          // Token is valid, proceed with fetching data
          setLoggedInUser(storedUsername);
          fetchTasksByTasks();
          fetchTasksByUsers();
          //fetchTables();
        } else {
          handleLogout();
        }
      } catch (error) {
          handleLogout();
      }
    }
  }, []);

  const handleSelectedpage = (selection) => {
     setUsername('');
     setPassword('');
     setEmail('');
     setResetToken('');
     setNewPassword('');
     setMessage("");
     setSection(selection);
  };

  useEffect(() => {
    if (loggedInUser) {
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (Array.isArray(tasksByTask) && tasksByTask.length > 0) {
      tasksByTask
        .filter(task => task.current === true)
        .forEach((task) => {
          setSelectedTaskByTask(task.taskName);
//          setTaskByTask(findTaskByTaskByName(task.taskName));
const foundTask = findTaskByTaskByName(task.taskName);

// Format the limitValue before setting it in the state
const formattedLimitValue = new Intl.NumberFormat('en-US', { 
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2 
}).format(Number(foundTask.limitValue));

// Create a new object with the formatted limitValue
const updatedTask = {
  ...foundTask,
  limitValue: formattedLimitValue
};

// Set the state with the updated task
setTaskByTask(updatedTask);
        });
    }
  }, [tasksByTask]);

  const fetchTasksByTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/tasks/tasks', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setSelectedTaskByTask('');
      setTasksByTasks(response.data); 
      //resetTaskForm();
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchTables = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/tables', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setTables(response.data); 
      //resetTaskForm();
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchTasksByUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/tasks/users', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setTasksByUsers(response.data);
      //resetTaskForm();
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const createTaskByTask = async (newTask) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/tasks/valuetask', newTask, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      setMessage("Failed to create task. Please check the details or try again.");
      console.error("Error creating task:", error);
    }
  };

  const createTaskByUser = async (newTask) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/tasks/valueusername', newTask, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      setMessage("Failed to create task. Please check the details or try again.");
      console.error("Error creating task:", error);
    }
  };

  const createTaskByExpense = async (newTask) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/tasks/valueexpense', newTask, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      setMessage("Failed to create task. Please check the details or try again.");
      console.error("Error creating task:", error);
    }
  };



  const updateTaskByTask = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/tasks/tasks/${id}`, taskByTask, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  const updateTaskByUser = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/tasks/users/${id}`, taskByUser, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  const updateAndSelectTask = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/tasks/select/${id}`, taskByTask, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  const deleteTaskByTask = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/tasks/task/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
  };

  const deleteTaskByUser = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/tasks/user/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchTasksByTasks();
      fetchTasksByUsers();
      fetchTables();
      resetTaskForm();
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
  };

  const handleTaskByTaskInputChange = (e) => {
    const { name, value } = e.target;
    setTaskByTask({ ...taskByTask, [name]: value });
    //console.log("name : "+name+" : value : "+ value);
  };

  const handleTaskByUserInputChange = (e) => {
    const { name, value } = e.target;
    setTaskByUser({ ...taskByUser, [name]: value });
  };

  const handleTaskByExpenseInputChange = (e) => {
    const { name, value } = e.target;
    setTaskByExpense({ ...taskByExpense, [name]: value });
  };

const handleTaskByTaskSubmit = (e) => {
  e.preventDefault();
  const taskId = findTaskIdByName(taskByTask.taskName);

  const numericValue = parseFloat(parseFloat(taskByTask.limitValue).toFixed(2));

  if (numericValue !== 0) {
  if (taskId) {
    updateTaskByTask(taskId); // Use taskId instead of taskByTask.id
  } else {
    // Prepare new task data
    const newTask = {
      ...newTaskByTask,
      taskName: taskByTask.taskName,
      measuringUnit: taskByTask.measuringUnit,
      limitValue: taskByTask.limitValue,
    };

    // Call the API directly with the new task data
    createTaskByTask(newTask);
  }
  }
};


  const handleTaskByUserSubmit = async (e) => {
    e.preventDefault();
    const userId = findTaskIdByNameAndEmail(taskByTask.taskName, taskByUser.userEmail);
    //console.log(userId+" "+taskByTask.taskName+" "+taskByUser.userEmail);
    if (userId) {
      updateTaskByUser(taskByUser.id);
    } else {
    resetNewTaskForm();
    const newTask = {
      ...newTaskByTask,
      userName: taskByUser.userName,
      userEmail: taskByUser.userEmail,
    };
      createTaskByUser(newTask);
    }
  };

  const handleTaskByExpenseSubmit = async (e) => {
    e.preventDefault();
    resetNewTaskForm();
    const newTask = {
      ...newTaskByTask,
      expenseDescription: taskByExpense.expenseDescription,
      expensePrice: taskByExpense.expensePrice,
    };

    const numericValue = parseFloat(parseFloat(taskByExpense.expensePrice).toFixed(2));

    if (numericValue !== 0) {
      createTaskByExpense(newTask);
    }
  };

  const handleCloseTaskModal = () => {
    resetTaskForm();
    setShowTaskModal(false);
  };

  const resetNewTaskForm = () => {
    setNewTaskByTask({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
    });
  };

  const resetTaskByTaskForm = () => {
    setTaskByTask({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
    });
  };

  const resetTaskByUserForm = () => {
    setTaskByUser({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
    });
  };


  const resetTaskForm = () => {
    setTaskByTask({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
    });
    setTaskByUser({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: 0,
    balance: 0
    });
    setTaskByExpense({
    id: null,
    accountEmail: '',
    groupEmail: '',
    copy: false,
    current: false,
    exceedingLimit: false,
    valueNumber: false,
    valueTask: false,
    valueUserName: false,
    datetime: '',
    limitValue: '',
    measuringUnit: '',
    taskName: '',
    userEmail: '',
    userName: '',
    number: 0,
    expenseDescription: '',
    expensePrice: '',
    balance: 0
    });
    setEditingTask(false);
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/users/login', {
        username,
        password,
      });
      if (response.status === 200) {
        const token = response.headers['authorization']?.split(' ')[1];
        if (token) {
          localStorage.setItem('token', token);
          localStorage.setItem('username', username);
          setLoggedInUser(username);
          setTasksByTasks([]);
          setTasksByUsers([]);
          setTasksByExpenses([]);
          setSelectedTaskByTask('');
          setSelectedTaskByUser('');
          resetNewTaskForm();
          resetTaskForm();
          setUsername('');
          setPassword('');
          setEmail('');
          setResetToken('');
          setNewPassword('');
          setMessage('');
          fetchTasksByTasks();
          fetchTasksByUsers();
          fetchTables();
          handlePageChange('tasks');
        } else {
          setMessage('Token not received');
        }
      } else {
        setMessage('Invalid username or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage(error.response?.data?.message || 'An error occurred during login.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setLoggedInUser(null);
    setTasksByTasks([]);
    setTasksByUsers([]);
    setUsername('');
    setPassword('');
    handlePageChange('tasks');
    setMessage('');
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/users/register', {
        email,
        password,
      });
      setMessage(response.data);
      setUsername('');
      setPassword('');
      setEmail('');
      setResetToken('');
      setNewPassword('');
      setSection('verification');
    } catch (error) {
      console.error('Registration error:', error);
      setMessage(error.response?.data?.message || 'An error occurred during registration.');
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`/api/users/verify?token=${resetToken}`);
      setMessage(response.data);
      setUsername('');
      setPassword('');
      setEmail('');
      setResetToken('');
      setNewPassword('');
      setSection('login');
    } catch (error) {
      console.error('Verification error:', error);
      setMessage('Verification failed.');
    }
  };

  const handleResendVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('/api/users/resend-verification', { email });
      setMessage(response.data);
      setUsername('');
      setPassword('');
      setEmail('');
      setResetToken('');
      setNewPassword('');
      setSection('verification');
    } catch (error) {
      console.error('Resend verification error:', error);
      setMessage('Failed to resend verification email.');
    }
  };

  const handleRequestPasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('/api/users/request-password-reset', { email });
      setMessage(response.data);
      setUsername('');
      setPassword('');
      setEmail('');
      setResetToken('');
      setNewPassword('');
      setSection('resetPassword');
    } catch (error) {
      console.error('Password reset request error:', error);
      setMessage('Failed to request password reset.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('/api/users/reset-password', {
        token: resetToken,
        newPassword,
      });
      setMessage(response.data);
      setUsername('');
      setPassword('');
      setEmail('');
      setResetToken('');
      setNewPassword('');
      setSection('login'); 
    } catch (error) {
      console.error('Reset password error:', error);
      setMessage('Failed to reset password.');
    }
  };

  const Page1 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
              <table style={{ ...styles.centeredTable2, border: 'none', background: 'white' }}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th colSpan="2" style={{ border: 'none' }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page2 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page3 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page4 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page5 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page6 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>


              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
<table style={{ ...styles.bgWhite, border: 'none' }} height="40%">
  <thead style={{ ...styles.bgWhiteThead, border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <th colSpan="4" style={{ textAlign: 'center', border: 'none' }}>&nbsp;</th>
    </tr>
    <tr style={{ border: 'none' }}>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="70%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="5%" style={{ border: 'none' }}>&nbsp;</th>
      <th width="20%" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
    </tr>
  </thead>
  <tbody style={{ border: 'none' }}>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
    <tr style={{ border: 'none' }}>
      <th colSpan="2" style={{ textAlign: 'right', border: 'none' }}>&nbsp;</th>
      <td style={{ border: 'none' }}>&nbsp;</td>
      <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
    </tr>
  </tbody>
</table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page7 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page8 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page9 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page10 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page11 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page11a = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={{ ...styles.centeredTable, border: 'none', background: 'white'}}>
                <thead style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                  <tr style={{ border: 'none' }}>
                    <th style={{ border: 'none' }}>&nbsp;</th>
                    <td style={{ textAlign: 'right', border: 'none' }}>&nbsp;</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
  );

  const Page12 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
            <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>0,00 Kg</td>
                </tr>
              </thead>
            </table>
            </div>
          </div>
  );

  const Page13 = () => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>0,00 Kg</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page14 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page15 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page16 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page17 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>0,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page18 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );


  const Page19 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>500,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>600,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page20 = ({saldoValue, ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>500,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>600,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>80,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>95,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}><b>{saldoValue}</b></td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

  const Page21 = ({ukupno, nabavljenoUkupno, nedostaje}) => (
          <div style={pageStyle}>
            {/* New table in the top-left corner */}
            <table style={{ ...styles.topLeftTable, border: 'none' }} className="table table-bordered table-light">
              <thead style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }}>ZADATAK : MARIO, JOSIP, IVANA I MARTA TREBAJU KUPITI 1000 Kg KRUMPIRA NA RAZNIM LOKACIJAMA ILI TRGOVINAMA</th>
                </tr>
              </thead>
              <tbody style={{ border: 'none' }}>
                <tr style={{ border: 'none' }}>
                  <td style={{ border: 'none' }}>ROK JE 7 DANA. SVI SU MEĐUSOBNO POVEZANI</td>
                </tr>
              </tbody>
            </table>

            {/* Adjusted central table */}
            <div style={styles.leftContainer}>
            <table style={styles.centeredTable2} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th colSpan="2" style={{ textAlign: 'center' }}>Praćenje troškova</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Količina</th>
                  <td style={{ textAlign: 'right' }}>1000,00 Kg</td>
                </tr>
              </tbody>
            </table>

              {/* Kontejner za tabele */}
              <div style={styles.tableContainer}>
              <table style={styles.bgMario} className="table table-bordered table-primary" height="40%">
                <thead style={styles.bgMarioThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Mario</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>100,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>500,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>600,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgJosipa} className="table table-bordered table-info" height="40%">
                <thead style={styles.bgJosipaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Josip</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'right' }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>25,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgIvana} className="table table-bordered table-warning" height="40%">
                <thead style={styles.bgIvanaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Ivana</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>80,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>95,00</td>
                  </tr>
                </tbody>
              </table>
              <table style={styles.bgMarta} className="table table-bordered table-danger" height="40%">
                <thead style={styles.bgMartaThead}>
                  <tr>
                    <th colSpan="4" style={{ textAlign: 'center' }}>Marta</th>
                  </tr>
                  <tr>
                    <th width="5%">RB</th>
                    <th width="70%">Opis</th>
                    <th width="5%">MJ</th>
                    <th width="20%" style={{ textAlign: 'right' }}>&nbsp;&nbsp;&nbsp;Iznos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>35,00</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Krumpir</td>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>15,00</td>
                  </tr>
                  <tr>
                    <th colSpan="2" style={{ textAlign: 'right' }}>Ukupno</th>
                    <td>Kg</td>
                    <td style={{ textAlign: 'right' }}>50,00</td>
                  </tr>
                </tbody>
              </table>
              </div>

              {/* Donja centralna tabela */}
              <table style={styles.centeredTable} className="table table-bordered table-success">
              <thead>
                <tr>
                  <th>Nabavljeno ukupno</th>
                  <td style={{ textAlign: 'right' }}>{nabavljenoUkupno}</td>
                </tr>
                <tr>
                  <th>Nedostaje</th>
                  <td style={{ textAlign: 'right' }}>{nedostaje}</td>
                </tr>
              </thead>
            </table>

            </div>
          </div>
  );

const Page22 = () => {
    return (
        <>
            <p style={{ fontSize: '20px' }}>&nbsp;</p>
            <p style={{ fontSize: '20px' }}>&nbsp;</p>

            <table style={{ ...tableContainer3, marginBottom: '0px' }} className="table table-bordered rounded-corners table-success">
                <thead>
                    <tr style={{ textAlign: 'center' }}>
                        <th translate="no" style={{ backgroundColor: colors.evenRowColor1, color: colors.evenTextColor1, padding: '10px' }}>
                            Email: {localStorage.getItem('username')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center', translate: 'no', backgroundColor: colors.oddRowColor1, color: colors.oddTextColor1, padding: '10px' }}>
                            Naziv zadatka: {taskByTask.taskName}
                        </td>
                    </tr>
                </tbody>
            </table>

            {selectedTaskByTask !== '' && tables.length > 0 && (
                <>
                    <p style={{ fontSize: '4px' }}>&nbsp;</p>
                    <table style={{ ...tableContainer3, marginBottom: '0px' }} className="table table-bordered rounded-corners table-success">
                        <thead>
                            <tr>
                                <th colSpan="2" style={{ textAlign: 'center', backgroundColor: colors.evenRowColor2, color: colors.evenTextColor2, padding: '10px' }}>
                                    Praćenje troškova
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="50%" style={{ textAlign: 'left', backgroundColor: colors.oddRowColor2, color: colors.oddTextColor2, padding: '10px' }}>
                                    Količina
                                </td>
                                <td style={{ textAlign: 'right', width: '50%', translate: 'no', backgroundColor: colors.oddRowColor2, color: colors.oddTextColor2, padding: '10px' }}>
                                    {new Intl.NumberFormat('en-US', {
                                        useGrouping: true,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(Number(-taskByTask.limitValue))}{' '}
                                    {taskByTask.measuringUnit}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}

            {selectedTaskByTask !== '' && tables.length > 0 && (
                <>
                    <p style={{ fontSize: '4px' }}>&nbsp;</p>
                    {tables.map((table, index) => renderTable(table, index))}
                </>
            )}

            {selectedTaskByTask !== '' && tables.length > 0 && (
                <>
                    <p style={{ fontSize: '4px' }}>&nbsp;</p>
                    <table style={{ ...tableContainer3, marginTop: '0px', marginBottom: '0px' }} className="table table-bordered rounded-corners table-success">
                        <thead>
                            <tr>
                                <th width="55%" style={{ backgroundColor: colors.evenRowColor3, color: colors.evenTextColor3, padding: '10px' }}>
                                    Nabavljeno ukupno
                                </th>
                                <th style={{ textAlign: 'right', width: '45%', translate: 'no', backgroundColor: colors.evenRowColor3, color: colors.evenTextColor3, padding: '10px' }}>
                                    {new Intl.NumberFormat('en-US', {
                                        useGrouping: true,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(Number(-taskByTask.limitValue + taskByTask.balance))}{' '}
                                    {taskByTask.measuringUnit}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="55%" style={{ backgroundColor: colors.oddRowColor3, color: colors.oddTextColor3, padding: '10px' }}>
                                    Nedostaje
                                </td>
                                <td style={{ textAlign: 'right', width: '45%', translate: 'no', backgroundColor: colors.oddRowColor3, color: colors.oddTextColor3, padding: '10px' }}>
                                    {new Intl.NumberFormat('en-US', {
                                        useGrouping: true,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(Number(-taskByTask.balance))}{' '}
                                    {taskByTask.measuringUnit}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
        </>
    );

};

  return (
     <>
    <div>
      {/* Container for Logo and Dropdown */}
      <div style={headerStyle}>
{loggedInUser ? (
          <Button variant="primary" onClick={handleShowModal} style={{
            position: 'fixed', 
            fontSize: widthLevel4,
            top: '20px', 
            left: '20px', 
            width: widthLevel5, // Adjust size as needed
            height: '40px'
          }}>
            Enter (zadaci, sudionici i troškovi)
          </Button>
) : (
<>
        <img 
          src={logo} 
          alt="Logo" 
          style={{
            position: 'fixed', 
            top: '20px', 
            left: '20px', 
            width: '200px', // Adjust size as needed
            height: '40px'
          }}
        />
</>
)}

        {/* Hamburger and Dropdown */}
        <div
          style={{
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            position: 'fixed',
            top: '20px',
            right: '20px',
            zIndex: 2,
          }}
          onClick={toggleDropdown}
        >
          {/* Hamburger icon */}
          <div>
            <div style={lineStyle}></div>
            <div style={lineStyle}></div>
            <div style={lineStyle}></div>
          </div>

          {/* Dropdown menu */}
          {isOpen && (
            <div
              ref={dropdownRef}
              style={{
                position: 'absolute',
                top: '40px',  // Adjust this if you want the dropdown to appear closer or further away from the hamburger icon
                right: '0',
                width: '150px',
                backgroundColor: 'white',
                boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                zIndex: 1,
              }}
          >
         {loggedInUser ? (
            <>
            <div onClick={() => handlePageChange('tasks')} style={dropdownItemStyle}>Tasks</div>
            <div onClick={() => handlePageChange('expensesdemo')} style={dropdownItemStyle}>Expense Demo</div>
            <div onClick={handleLogout} style={dropdownItemStyle}>Logout</div>
            </>
         ) : (
            <>
            <div onClick={() => handlePageChange('expensesdemo')} style={dropdownItemStyle}>Expense Demo</div>
            <div onClick={() => handlePageChange('tasks')} style={dropdownItemStyle}>Login</div>
            </>
         )}
          </div>
        )}
      </div>
      </div>


      {/* Page Content */}
      {activePage === 'tasks' && (
    <div>
      {loggedInUser ? (
<div style={pageStyle2}>  
{<Page22 />}
  <Modal show={showModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
      <Modal.Title>Enter (zadaci, sudionici i troškovi)</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Button 
        variant="secondary" 
        className="mb-3"
        onClick={() => setShowTaskParticipant(!showTaskParticipant)}
      >
        {showTaskParticipant ? 'Sakrij (zadaci i sudionici)' : 'Prikaži (zadaci i sudionici)'}
      </Button>

      {showTaskParticipant && (
        <>
          <h5>Zadatak</h5>
          <Button 
            variant="primary" 
            className="mb-3"
            onClick={() => setShowTaskForm(!showTaskForm)}
          >
            {showTaskForm ? 'Sakrij Zadatak' : 'Dodaj / Promjeni Zadatak'}
          </Button>

          {showTaskForm && (
            <Form onSubmit={handleTaskByTaskSubmit}>
              <Form.Group controlId="formTaskName">
                <Form.Label>Naziv Zadatka</Form.Label>
                <Form.Control
                  type="text"
                  name="taskName"
                  value={taskByTask.taskName}
                  onChange={handleTaskByTaskInputChange}
                  placeholder="Unesite naziv zadatka"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formTaskUnit">
                <Form.Label>Mjerna Jedinica</Form.Label>
                <Form.Control
                  type="text"
                  name="measuringUnit"
                  value={taskByTask.measuringUnit}
                  onChange={handleTaskByTaskInputChange}
                  placeholder="Unesite mjernu jedinicu"
                  required
                />
              </Form.Group>
    <Form.Group controlId="formTaskQuantity">
      <Form.Label>Količina</Form.Label>
      <NumericFormat
        customInput={Form.Control}
        name="limitValue"
        value={taskByTask.limitValue}
        onValueChange={(values) => {
          const { value } = values;
          handleTaskByTaskInputChange({
            target: { name: 'limitValue', value: value }
          });
        }}
        placeholder="Unesite količinu"
        allowNegative={true}
        required
      />
    </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Dodaj / Promjeni Zadatak
              </Button>
            </Form>
          )}

          <hr />

          <Form.Group controlId="formTaskList" className="mt-4">
            <Form.Label>Lista Zadatka</Form.Label>
            <Form.Control
              as="select"
              name="taskName"
              value={selectedTaskByTask}
              onChange={handleTaskChange}
            >
              <option value="">Odaberite zadatak</option>
              {Array.isArray(tasksByTask) && tasksByTask.map((task) => (
                <option 
                  key={task.id} 
                  value={task.taskName} 
                  selected={task.current}
                  translate="no"
                >
                  {task.taskName}
                </option>
              ))}
            </Form.Control>
            <Button variant="danger" onClick={handleDeleteTask} className="mt-2">
              Obriši Zadatak
            </Button>
          </Form.Group>

          <hr />

          <h5>Sudionik</h5>

          <Button 
            variant="primary" 
            className="mb-3"
            onClick={() => setShowParticipantForm(!showParticipantForm)}
          >
            {showParticipantForm ? 'Sakrij Sudionika' : 'Dodaj Sudionika'}
          </Button>

          {showParticipantForm && (
            <Form className="mt-4" onSubmit={handleTaskByUserSubmit}>
              <Form.Group controlId="formParticipantName">
                <Form.Label>Naziv Sudionika</Form.Label>
                <Form.Control
                  type="text"
                  name="userName"
                  value={taskByUser.userName}
                  onChange={handleTaskByUserInputChange}
                  placeholder="Unesite naziv sudionika"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formParticipantEmail">
                <Form.Label>Email Sudionika</Form.Label>
                <Form.Control
                  type="email"
                  name="userEmail"
                  value={taskByUser.userEmail}
                  onChange={handleTaskByUserInputChange}
                  placeholder="Unesite email sudionika"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Dodaj Sudionika
              </Button>
            </Form>
          )}

          <hr />

          <Form.Group controlId="formParticipantList" className="mt-4">
            <Form.Label>Lista Sudionika</Form.Label>
            <Form.Control
              as="select"
              name="userEmail"
              value={selectedTaskByUser}
              onChange={handleUserChange}
            >
              <option value="">Odaberite sudionika</option>
              {Array.isArray(tasksByUser) && tasksByUser.map((task) => (
                <option key={task.id} value={task.userEmail} translate="no">
                  {task.userEmail}
                </option>
              ))}
            </Form.Control>
            <Button variant="danger" onClick={handleDeleteUser} className="mt-2">
              Obriši Sudionika
            </Button>
          </Form.Group>
        </>
      )}

      <hr />

      <h5>Trošak</h5>
      <Form onSubmit={handleTaskByExpenseSubmit}>
        <Form.Group controlId="formTaskList2">
          <Form.Label>Lista Zadatka</Form.Label>
          <Form.Control
            as="select"
            name="taskName"
            value={selectedTaskByTask}
            onChange={handleTaskChange}
          >
            <option value="">Odaberite zadatak</option>
            {Array.isArray(tasksByTask) && tasksByTask.map((task) => (
              <option 
                key={task.id} 
                value={task.taskName} 
                selected={task.current}
                translate="no"
              >
                {task.taskName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formCostDescription">
          <Form.Label>Opis Troška</Form.Label>
          <Form.Control
            type="text"
            name="expenseDescription"
            value={taskByExpense.expenseDescription}
            onChange={handleTaskByExpenseInputChange}
            placeholder="Unesite opis troška"
            required
          />
        </Form.Group>
    <Form.Group controlId="formCost">
      <Form.Label>Količina</Form.Label>
      <NumericFormat
        customInput={Form.Control}
        name="expsensePrice"
        value={taskByExpense.expensePrice}
        onValueChange={(values) => {
          const { value } = values;
          handleTaskByExpenseInputChange({
            target: { name: 'expensePrice', value: value }
          });
        }}
        placeholder="Unesite količinu"
        allowNegative={true}
        required
      />
    </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Dodaj Trošak
        </Button>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        Zatvori
      </Button>
    </Modal.Footer>
  </Modal>
</div>
      ) : (
        <div  style={pageContainer}>
     {section === 'register' && (
        <div style={pageContent}>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
          <h1>Register</h1>
          <form onSubmit={handleRegister}>
            <div className="form-group">
              <p>
              <label>Email</label>
              </p>
              <p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              </p>
            </div>
            <div className="form-group">
              <p>
              <label>Password</label>
              </p>
              <p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              </p>
            </div>
	    <p>
            <button type="submit" className="btn btn-primary">Register</button>
	    </p>
	    <p style={{ marginTop: '0px', marginBottom: '0px' }}>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('verification')}>Verification</button>
	    </p>
            <p style={{ marginTop: '0px', marginBottom: '0px' }}>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('resetPassword')}>Reset Password</button>
            </p>
            <p style={{ marginTop: '0px', marginBottom: '0px' }}>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('resendVerificationEmail')}>Resend Verification Email</button>
	    </p>
            <p style={{ marginTop: '0px' }}>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('requestPasswordReset')}>Request Password Reset</button>
	    </p>
	    <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('login')}>Login</button>
	    </p>
        {message && <p>{message}&nbsp;</p>}
          </form>
        </div>
      )}

      {section === 'login' && (
        <div style={pageContent}>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
          <h1>Login</h1>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <p>
              <label>Email</label>
              </p>
              <p>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              </p>
            </div>
            <div className="form-group">
              <p>
              <label>Password</label>
              </p>
              <p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              </p>
            </div>
            <p>
            <button type="submit" className="btn btn-primary">Login</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('register')}>Register</button>
            </p>
        {message && <p>{message}&nbsp;</p>}
          </form>
        </div>
      )}

      {section === 'verification' && (
        <div style={pageContent}>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
          <h1>Email Verification</h1>
          <form onSubmit={handleVerify}>
            <div className="form-group">
              <p>
              <label>Verification Token</label>
              </p>
              <p>
              <input
                type="text"
                value={resetToken}
                onChange={(e) => setResetToken(e.target.value)}
                required
              />
              </p>
            </div>
            <p>
            <button type="submit" className="btn btn-primary">Verify Email</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('register')}>Register</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('login')}>Login</button>
            </p>
        {message && <p>{message}&nbsp;</p>}
          </form>
        </div>
      )}

      {section === 'resetPassword' && (
        <div style={pageContent}>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
          <h1>Reset Password</h1>
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <p>
              <label>Reset Token</label>
              </p>
              <p>
              <input
                type="text"
                value={resetToken}
                onChange={(e) => setResetToken(e.target.value)}
                required
              />
              </p>
            </div>
            <div className="form-group">
              <p>
              <label>New Password</label>
              </p>
              <p>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              </p>
            </div>
            <p>
            <button type="submit" className="btn btn-primary">Reset Password</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('register')}>Register</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('login')}>Login</button>
            </p>
        {message && <p>{message}&nbsp;</p>}
          </form>
        </div>
      )}

      {section === 'resendVerificationEmail' && (
        <div style={pageContent}>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
          <h1>Resend Verification Email</h1>
          <form onSubmit={handleResendVerification}>
            <div className="form-group">
              <p>
              <label>Email:</label>
              </p>
              <p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              </p>
            </div>
            <p>
            <button type="submit" className="btn btn-primary">Resend Verification Email</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('register')}>Register</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('login')}>Login</button>
            </p>
        {message && <p>{message}&nbsp;</p>}
          </form>
        </div>
      )}

      {section === 'requestPasswordReset' && (
        <div style={pageContent}>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
      <p style={{ fontSize: widthLevel3 }}>&nbsp;</p>
          <h1>Request Password Reset</h1>
          <form onSubmit={handleRequestPasswordReset}>
            <div className="form-group">
              <p>
              <label>Email</label>
              </p>
              <p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              </p>
            </div>
            <p>
            <button type="submit" className="btn btn-primary">Request Password Reset</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('register')}>Register</button>
            </p>
            <p>
            <button className="btn btn-primary" onClick={() => handleSelectedpage('login')}>Login</button>
            </p>
        {message && <p>{message}&nbsp;</p>}
          </form>
        </div>
      )}
        </div>
      )}
    </div>
      )}

      {activePage === 'expensesdemo' && (
         <>
         {currentPage === 1 && <Page1 />}
         {currentPage === 2 && <Page2 />}
         {currentPage === 3 && <Page3 />}
         {currentPage === 4 && <Page4 />}
         {currentPage === 5 && <Page5 />}
         {currentPage === 6 && <Page6 />}
         {currentPage === 7 && <Page7 />}
         {currentPage === 8 && <Page8 />}
         {currentPage === 9 && <Page9 />}
         {currentPage === 10 && <Page10 />}
         {currentPage === 11 && <Page11 />}
         {currentPage === 12 && <Page11a />}
         {currentPage === 13 && <Page12 />}
         {currentPage === 14 && <Page13 />}
         {currentPage === 15 && <Page14 saldoValue="&nbsp;" ukupno="0,00" nabavljenoUkupno="0,00 Kg" nedostaje="1000,00 Kg" />}
         {currentPage === 16 && <Page14 saldoValue="100,00" ukupno="0,00" nabavljenoUkupno="0,00 Kg" nedostaje="1000,00 Kg"  />}
         {currentPage === 17 && <Page14 saldoValue="&nbsp;" ukupno="0,00" nabavljenoUkupno="0,00 Kg" nedostaje="1000,00 Kg"  />}
         {currentPage === 18 && <Page14 saldoValue="100,00" ukupno="0,00" nabavljenoUkupno="0,00 Kg" nedostaje="1000,00 Kg"  />}
         {currentPage === 19 && <Page15 saldoValue="&nbsp;" ukupno="100,00" nabavljenoUkupno="100,00 Kg" nedostaje="900,00 Kg"  />}
         {currentPage === 20 && <Page15 saldoValue="25,00" ukupno="0,00" nabavljenoUkupno="100,00 Kg" nedostaje="900,00 Kg"  />}
         {currentPage === 21 && <Page15 saldoValue="&nbsp;" ukupno="0,00" nabavljenoUkupno="100,00 Kg" nedostaje="900,00 Kg"  />}
         {currentPage === 22 && <Page15 saldoValue="25,00" ukupno="0,00" nabavljenoUkupno="100,00 Kg" nedostaje="900,00 Kg"  />}
         {currentPage === 23 && <Page16 saldoValue="&nbsp;" ukupno="25,00" nabavljenoUkupno="125,00 Kg" nedostaje="875,00 Kg"  />}
         {currentPage === 24 && <Page16 saldoValue="15,00" ukupno="0,00" nabavljenoUkupno="125,00 Kg" nedostaje="875,00 Kg"  />}
         {currentPage === 25 && <Page16 saldoValue="&nbsp;" ukupno="0,00" nabavljenoUkupno="125,00 Kg" nedostaje="875,00 Kg"  />}
         {currentPage === 26 && <Page16 saldoValue="15,00" ukupno="0,00" nabavljenoUkupno="125,00 Kg" nedostaje="875,00 Kg"  />}
         {currentPage === 27 && <Page17 saldoValue="&nbsp;" ukupno="15,00" nabavljenoUkupno="140,00 Kg" nedostaje="860,00 Kg"  />}
         {currentPage === 28 && <Page17 saldoValue="35,00" ukupno="0,00" nabavljenoUkupno="140,00 Kg" nedostaje="860,00 Kg"  />}
         {currentPage === 29 && <Page17 saldoValue="&nbsp;" ukupno="0,00" nabavljenoUkupno="140,00 Kg" nedostaje="860,00 Kg"  />}
         {currentPage === 30 && <Page17 saldoValue="35,00" ukupno="0,00" nabavljenoUkupno="140,00 Kg" nedostaje="860,00 Kg"  />}
         {currentPage === 31 && <Page18 saldoValue="&nbsp;" ukupno="35,00" nabavljenoUkupno="175,00 Kg" nedostaje="825,00 Kg"  />}
         {currentPage === 32 && <Page18 saldoValue="500,00" ukupno="100,00" nabavljenoUkupno="175,00 Kg" nedostaje="825,00 Kg"  />}
         {currentPage === 33 && <Page18 saldoValue="&nbsp;" ukupno="100,00" nabavljenoUkupno="175,00 Kg" nedostaje="825,00 Kg"  />}
         {currentPage === 34 && <Page18 saldoValue="500,00" ukupno="100,00" nabavljenoUkupno="175,00 Kg" nedostaje="825,00 Kg"  />}
         {currentPage === 35 && <Page19 saldoValue="&nbsp;" ukupno="600,00" nabavljenoUkupno="675,00 Kg" nedostaje="325,00 Kg"  />}
         {currentPage === 36 && <Page19 saldoValue="80,00" ukupno="15,00" nabavljenoUkupno="675,00 Kg" nedostaje="325,00 Kg"  />}
         {currentPage === 37 && <Page19 saldoValue="&nbsp;" ukupno="15,00" nabavljenoUkupno="675,00 Kg" nedostaje="325,00 Kg"  />}
         {currentPage === 38 && <Page19 saldoValue="80,00" ukupno="15,00" nabavljenoUkupno="675,00 Kg" nedostaje="325,00 Kg"  />}
         {currentPage === 39 && <Page20 saldoValue="&nbsp;" ukupno="95,00" nabavljenoUkupno="755,00 Kg" nedostaje="245,00 Kg"  />}
         {currentPage === 40 && <Page20 saldoValue="15,00" ukupno="35,00" nabavljenoUkupno="755,00 Kg" nedostaje="245,00 Kg"  />}
         {currentPage === 41 && <Page20 saldoValue="&nbsp;" ukupno="35,00" nabavljenoUkupno="755,00 Kg" nedostaje="245,00 Kg"  />}
         {currentPage === 42 && <Page20 saldoValue="15,00" ukupno="35,00" nabavljenoUkupno="755,00 Kg" nedostaje="245,00 Kg"  />}
         {currentPage === 43 && <Page21 ukupno="50,00" nabavljenoUkupno="770,00 Kg" nedostaje="230,00 Kg" />}
         </>
      )}
    </div>
      <style>
        {`
          .table-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
          }

          .table.rounded-corners {
            border-collapse: separate;
            border-spacing: 0;
            margin-bottom: 20px;
          }

          .table.rounded-corners th, .table.rounded-corners td {
            border: 10;
            box-shadow: 0 0 0 1px black;
            color: black;
          }

table.rounded-corners thead tr:first-child th:first-child {
 border-top-left-radius: 10px;
}

table.rounded-corners thead tr:last-child th:first-child {
 border-bottom-left-radius: 10px;
}

table.rounded-corners thead tr:first-child th:last-child {
 border-top-right-radius: 10px;
}

table.rounded-corners thead tr:last-child th:last-child {
 border-bottom-right-radius: 10px;
}

table.rounded-corners tbody tr:first-child td:first-child {
 border-top-left-radius: 10px;
}

table.rounded-corners tbody tr:last-child td:first-child {
 border-bottom-left-radius: 10px;
}

table.rounded-corners tbody tr:first-child td:last-child {
 border-top-right-radius: 10px;
}

table.rounded-corners tbody tr:last-child td:last-child {
 border-bottom-right-radius: 10px;
}
        `}
      </style>
      </>
  );
}

const headerStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  padding: '40px', // Adjusted padding to fit content better
  zIndex: 3,
  backgroundColor: '#fff',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const contentStyle = {
  marginTop: '80px', // Space for header
  padding: '0px',
  width: '100%',
  boxSizing: 'border-box',
  height: 'calc(100vh - 80px)', // Adjust height to fit within viewport
  overflow: 'auto', // Enable both vertical and horizontal scrolling
};

const lineStyle = {
  width: '25px',
  height: '3px',
  backgroundColor: 'black',
  margin: '4px 0',
};

const dropdownItemStyle = {
  display: 'block',
  padding: '10px',
  textDecoration: 'none',
  color: 'black',
  backgroundColor: 'white',
  borderBottom: '1px solid #ddd',
  cursor: 'pointer',
};

const container = {
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '0 auto',
  padding: '20px',
  // Base styles for mobile first
  maxWidth: '100%', 
};

export default App;
